


import FETCHING from '../../library/fetching'
import DATAMASTER from '../../library/dataMaster'

export default {
  data() {
    return {

      form : {
        id : '',
        program_kode : '',
        kegiatan_kode : '',
        kode : '',
        uraian : '',
        keterangan : '',
      },

      // ====================================== CONTOH AUTOCOMPLETE ====================================
      autocomplete_db : '',
      // ====================================== CONTOH AUTOCOMPLETE ====================================
      
      filterku : {
          urusan_kode : '',
          urusan_bidang_kode : '',
          program_kode : '',
      },



     
      list_data : [],

      page_first: 1,
      page_last: 0,
      page_limit : 10,
      cari_value: "",
      cek_load_data : true,


      mdl_add: false,
      mdl_edit: false,
      mdl_hapus : false,
      btn_add: false,


      FETCHING : FETCHING,
      DATAMASTER : DATAMASTER,
    }
  },
  methods: {


    getView : function(){
      this.$store.commit("shoWLoading");
      fetch(this.$store.state.url.RL_DM_KEGIATAN_SUB + "view", {
          method: "POST",
          headers: {
          "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
              data_ke: this.page_first,
              cari_value: this.cari_value,
              page_limit : this.page_limit,
              urusan_kode : this.filterku.urusan_kode,
              urusan_bidang_kode : this.filterku.urusan_bidang_kode,
              program_kode : this.filterku.program_kode,
          })
      })
          .then(res => res.json())
          .then(res_data => {
              this.list_data = res_data.data;
              this.page_last = res_data.jml_data;
              this.$store.commit("hideLoading");
              console.log(res_data);
      });
    },


    addData : function() {
      fetch(this.$store.state.url.RL_DM_KEGIATAN_SUB + "addData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify(this.form)
      }).then(res_data => {
          this.Notify('Sukses Menambah Data', 'primary', 'check_circle_outline');
          this.getView();
      });
    },


    editData : function(){
      fetch(this.$store.state.url.RL_DM_KEGIATAN_SUB + "editData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify(this.form)
      }).then(res_data => {
          this.Notify('Sukses Merubah Data', 'warning', 'check_circle_outline');
          this.getView();
      });
    },

    removeData : function(E){
      fetch(this.$store.state.url.RL_DM_KEGIATAN_SUB + "removeData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify(this.form)
      }).then(res_data => {
          this.Notify('Sukses Menghapus Data', 'negative', 'check_circle_outline');
          this.getView();
      });

    },

    selectData : function(data){

        console.log(data);
        this.form.kegiatan_kode = data.kode_full;
    },

    selectSubKegiatan : function(data){
        this.form.id =  data.id ;
        this.form.program_kode =  data.program_kode ;
        this.form.kegiatan_kode =  data.kegiatan_kode ;
        this.form.kode =  data.kode ;
        this.form.uraian =  data.uraian ;
        this.form.keterangan =  data.keterangan ;
    },


    // ====================================== CONTOH eDOC ====================================
    onChangex(data){
      // await DATAMASTER.postMasterUrusanBidang(val)
    },
    async asyncFetchUrusan(){
      await DATAMASTER.postMasterUrusan('');
      this.form.urusan_kode = this.$store.state.list_urusan[0].kode;
      this.asyncFetchUrusanBidang(this.form.urusan_kode)
    },
    async asyncFetchUrusanBidang(val){
      await DATAMASTER.postMasterUrusanBidang(val);
      await this.asyncFetchProgram(this.form.urusan_kode, this.form.urusan_bidang_kode)
    },
    async asyncFetchProgram(urusan_kode, urusan_bidang_kode){
      await DATAMASTER.postMasterProgram(urusan_kode, urusan_bidang_kode)
    },
    // ====================================== CONTOH eDOC ====================================

  // ====================================== CONTOH AUTOCOMPLETE ====================================
    autocomplete_urusan_filter : function (val, update) {
        update(() => {
          if (val === '') {}
          else {FETCHING.getContohAtocomplete(val)}
        })
    },

    // ====================================== CONTOH AUTOCOMPLETE ====================================








    // ====================================== PAGINATE ====================================
        Notify : function(message, positive, icon){
          this.$q.notify({
            message: message,
            color: positive,
            icon: icon,
            position : 'top',
            timeout: 500,
          })
        },
        btn_prev : function(){
            this.cek_load_data = true;
            if(this.page_first>1){
                this.page_first--
            }else{
                this.page_first = 1;
            }
            this.getView();
        },

        btn_next : function(){
            if(this.page_first >= this.page_last){
                this.page_first == this.page_last
            }else{
                this.page_first++;
            }
            this.getView();
        },

        cari_data : function(){
            this.page_first = 1;
            this.getView();
        },

          indexing : function(index){
              var idx = ((this.page_first-1)*this.page_limit)+index
              return idx
          },


    // ====================================== PAGINATE ====================================







  },

  mounted () {
    FETCHING.getContohAtocomplete('')
    this.asyncFetchUrusan();
  //   DATAMASTER.postMasterUrusan('')
    this.getView();
  },
}
